import { useEffect, useState } from "react";
import AuthService from "../auth/AuthService";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import AuthorService from "../services/AuthorService";
import "styles/WelcomeMessage.scss";

const WelcomeMessage = () => {
    const { t } = useTranslation();
    const names = AuthService.getUserNames();
    const externalUserId = AuthService.getUserId();

    const [authorId, setAuthorId] = useState<number | null>(null);

    useEffect(() => {
        const fetchAuthorId = async () => {
            try {
                const response = await AuthorService.getAllAuthors();
                const author = response.data.find(
                    (author: any) => author.externalUserId === externalUserId,
                );
                if (author) {
                    setAuthorId(author.id);
                }
            } catch (error) {
                console.error("Failed to fetch author ID", error);
            }
        };

        fetchAuthorId();
    }, [externalUserId]);

    return names && authorId ? (
        <div className="welcome-message">
            {t("navbar.message")}
            <b>
                <NavLink
                    to={`/edit-author/${authorId}`}
                    className="author-link">
                    {names}
                </NavLink>
            </b>
        </div>
    ) : null;
};

export default WelcomeMessage;
