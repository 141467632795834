// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pocs-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.sorting-controls {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 15px;
}

.sorting-controls select,
.sorting-controls button {
  margin-left: 5px;
  min-width: 50px;
}

.search-input-group {
  align-items: right;
}

.search-row {
  margin-bottom: 20px;
}

.col-lg-1,
.col-lg-3 {
  padding-right: 5px;
  padding-left: 5px;
}`, "",{"version":3,"sources":["webpack://./src/styles/SuggestionGrid.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,4DAAA;EACA,SAAA;AACJ;;AACA;EACI,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,mBAAA;AAEJ;;AACA;;EAEI,gBAAA;EACA,eAAA;AAEJ;;AAAA;EACI,kBAAA;AAGJ;;AADA;EACI,mBAAA;AAIJ;;AADA;;EAEI,kBAAA;EACA,iBAAA;AAIJ","sourcesContent":[".pocs-grid {\n    display: grid;\n    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));\n    gap: 20px;\n}\n.sorting-controls {\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n    margin-bottom: 15px;\n}\n\n.sorting-controls select,\n.sorting-controls button {\n    margin-left: 5px;\n    min-width: 50px;\n}\n.search-input-group {\n    align-items: right;\n}\n.search-row {\n    margin-bottom: 20px;\n}\n\n.col-lg-1,\n.col-lg-3 {\n    padding-right: 5px;\n    padding-left: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
