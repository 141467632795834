// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.welcome-message {
  font-size: 1.2em;
  margin-top: 10px;
  margin-left: 2em;
}

.author-link {
  color: #007bff; /* Adjust the color as needed */
  text-decoration: none;
}

.author-link:hover {
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/styles/WelcomeMessage.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,gBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,cAAA,EAAA,+BAAA;EACA,qBAAA;AACJ;;AAEA;EACI,0BAAA;AACJ","sourcesContent":[".welcome-message {\n    font-size: 1.2em;\n    margin-top: 10px;\n    margin-left: 2em;\n}\n\n.author-link {\n    color: #007bff; /* Adjust the color as needed */\n    text-decoration: none;\n}\n\n.author-link:hover {\n    text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
