import AuthService from "../auth/AuthService";
import pocApiAxios from "./PocApiAxiosBase";

const AuthorImageService = {
    async updateProfileImage(
        authorId: number,
        blob: Blob,
        fileName: string,
    ): Promise<any> {
        const authToken = await AuthService.getToken();
        const formData = new FormData();
        formData.append("file", blob, `${fileName}`);
        const options = {
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "multipart/form-data",
            },
        };

        return pocApiAxios.put<any>(
            `/authors/${authorId}/profile-image`,
            formData,
            options,
        );
    },
};

export default AuthorImageService;
