import { useState, useRef, useEffect } from "react";
import Cropper from "react-easy-crop";
import { useTranslation } from "react-i18next";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import AuthorImageService from "../services/AuthorImageService";
import "styles/AuthorImageCrop.scss";
import toast from "react-hot-toast";

interface AuthorImageCropProps {
    widthAspect: number;
    heightAspect: number;
    authorId: number;
    profileImageUrl?: string | null;
    onSuccess: () => void;
    isOpen: boolean;
    toggle: () => void;
}

const AuthorImageCrop: React.FC<AuthorImageCropProps> = ({
    widthAspect,
    heightAspect,
    authorId,
    profileImageUrl,
    onSuccess,
    isOpen,
    toggle,
}) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null);
    const [file, setFile] = useState<string | null>(null);
    const [fileName, setFileName] = useState<string>("");
    const [isImageSelected, setIsImageSelected] = useState(false);
    const hiddenFileInput = useRef<HTMLInputElement>(null);

    const { t } = useTranslation();

    useEffect(() => {
        setFile(profileImageUrl || null);
    }, [profileImageUrl]);

    useEffect(() => {
        if (isOpen && hiddenFileInput.current) {
            hiddenFileInput.current.click();
        }
    }, [isOpen]);

    const handleFileUploadClick = () => {
        if (hiddenFileInput.current) {
            hiddenFileInput.current.click();
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setFile(reader.result as string);
                setIsImageSelected(true);
            });
            reader.readAsDataURL(e.target.files[0]);
            setFileName(e.target.files[0].name);
        }
    };

    const onCropComplete = (_: any, croppedAreaPixels: any) => {
        setCroppedAreaPixels(croppedAreaPixels);
    };

    const getCroppedImg = async () => {
        const image = new Image();
        image.src = file!;
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d")!;
        const { width, height } = image;

        canvas.width = croppedAreaPixels.width;
        canvas.height = croppedAreaPixels.height;
        ctx.drawImage(
            image,
            croppedAreaPixels.x,
            croppedAreaPixels.y,
            croppedAreaPixels.width,
            croppedAreaPixels.height,
            0,
            0,
            croppedAreaPixels.width,
            croppedAreaPixels.height,
        );

        return new Promise<Blob | null>((resolve) => {
            canvas.toBlob(resolve, "image/jpeg");
        });
    };

    const saveImage = async () => {
        const croppedImageBlob = await getCroppedImg();
        if (croppedImageBlob) {
            try {
                await AuthorImageService.updateProfileImage(
                    authorId,
                    croppedImageBlob,
                    fileName,
                );
                onSuccess();
                toggleWithReset();
                toast.success(t("author.imageUpdateSuccess"));
            } catch (error) {
                console.error("Failed to update profile image", error);
                toast.error(t("author.imageUpdateError"));
            }
        }
    };

    const resetFileState = () => {
        setFile(null);
        setFileName("");
        setCrop({ x: 0, y: 0 });
        setZoom(1);
        setCroppedAreaPixels(null);
        setIsImageSelected(false);
    };

    const toggleWithReset = () => {
        if (isOpen) {
            resetFileState();
        }
        toggle();
    };

    return (
        <>
            <div className="filePickerContainer">
                <input
                    ref={hiddenFileInput}
                    type="file"
                    accept="image/png, image/jpeg"
                    onChange={handleChange}
                    style={{ display: "none" }}
                />
            </div>

            <Modal
                isOpen={isOpen}
                toggle={toggleWithReset}
                className="image-crop-modal">
                <ModalBody>
                    {isImageSelected ? (
                        <div className="crop-container2">
                            <Cropper
                                image={file || ""}
                                crop={crop}
                                zoom={zoom}
                                aspect={widthAspect / heightAspect}
                                onCropChange={setCrop}
                                onCropComplete={onCropComplete}
                                onZoomChange={setZoom}
                            />
                        </div>
                    ) : (
                        <Button onClick={handleFileUploadClick}>
                            {t("editPocArticle.chooseImage")}
                        </Button>
                    )}
                </ModalBody>
                <p>{t("coverImage.allowedFormats")}: JPG, JPEG, PNG </p>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleWithReset}>
                        {t("articleFiles.cancel")}
                    </Button>
                    <Button
                        color="primary"
                        onClick={saveImage}
                        disabled={!file}>
                        {t("editPocArticle.save")}
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default AuthorImageCrop;
