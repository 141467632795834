import { ChangeEvent, useState, useEffect, useMemo } from "react";
import {
    Form,
    FormGroup,
    Label,
    FormFeedback,
    Input,
    Button,
    Col,
    Row,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import PocLinkService from "services/PocLinksService";

interface PocEditLinkState {
    url: string;
    message: string;
    valid: boolean;
    changed: boolean;
}

interface PocEditLinkProps {
    id: number;
    sourceCodeLink: string;
    caseStudyLink: string;
    onUpdate: (type: "sourceCode" | "caseStudy", url: string) => void;
    setCaseStudyLinkDirty: (isDirty: boolean) => void;
    setSourceCodeLinkDirty: (isDirty: boolean) => void;
}

interface PocEditLinksInitialValues {
    sourceCodeLink: string;
    caseStudyLink: string;
}

export default function PocEditLinks({
    id,
    sourceCodeLink,
    caseStudyLink,
    onUpdate,
    setCaseStudyLinkDirty,
    setSourceCodeLinkDirty,
}: PocEditLinkProps) {
    const { t } = useTranslation();
    const urlRegex = useMemo(
        () =>
            /^(?:(?:https?|http):\/\/).(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
        [],
    );
    const [initialValues, setInitialValues] =
        useState<PocEditLinksInitialValues>({
            sourceCodeLink: sourceCodeLink,
            caseStudyLink: caseStudyLink,
        });

    const [editLinks, setEditLinks] = useState<{
        sourceCode: PocEditLinkState;
        caseStudy: PocEditLinkState;
    }>({
        sourceCode: {
            url: sourceCodeLink,
            message: "",
            valid: true,
            changed: false,
        },
        caseStudy: {
            url: caseStudyLink,
            message: "",
            valid: true,
            changed: false,
        },
    });

    useEffect(() => {
        setEditLinks({
            sourceCode: {
                url: sourceCodeLink,
                message: "",
                valid: urlRegex.test(sourceCodeLink),
                changed: false,
            },
            caseStudy: {
                url: caseStudyLink,
                message: "",
                valid: urlRegex.test(caseStudyLink),
                changed: false,
            },
        });
    }, [sourceCodeLink, caseStudyLink, urlRegex]);

    const handleInputChange = (
        e: ChangeEvent<HTMLInputElement>,
        type: "sourceCode" | "caseStudy",
    ) => {
        const { value } = e.target;
        const isValid = urlRegex.test(value);

        if (type === "sourceCode") {
            setCaseStudyLinkDirty(initialValues.sourceCodeLink !== value);
        } else {
            setSourceCodeLinkDirty(initialValues.caseStudyLink !== value);
        }

        setEditLinks((prevLinks) => ({
            ...prevLinks,
            [type]: {
                ...prevLinks[type],
                url: value,
                valid: isValid,
                message: isValid ? "" : t("editPocLinks.errorInvalidUrl"),
                changed: true,
            },
        }));
    };

    const handleSaveLink = async (type: "sourceCode" | "caseStudy") => {
        const linkState = editLinks[type];
        if (linkState.valid && linkState.changed) {
            try {
                await PocLinkService.savePocLinks(linkState.url, id, type);
                toast.success(t("editPocLinks.saveLinkSuccess"));
                if (type === "sourceCode") {
                    setInitialValues({
                        sourceCodeLink: linkState.url,
                        caseStudyLink: initialValues.caseStudyLink,
                    });
                    setSourceCodeLinkDirty(false);
                } else {
                    setInitialValues({
                        sourceCodeLink: initialValues.sourceCodeLink,
                        caseStudyLink: linkState.url,
                    });
                    setCaseStudyLinkDirty(false);
                }

                onUpdate(type, linkState.url);
            } catch (error) {
                toast.error(t("editPocLinks.saveLinkError"));
            }
        }
    };

    return (
        <>
            {(["sourceCode", "caseStudy"] as const).map((key) => (
                <Form key={key}>
                    <FormGroup>
                        <Label for={key}>{t(`editPocLinks.${key}`)}</Label>
                        <Row>
                            <Col>
                                <Input
                                    id={key}
                                    name={key}
                                    type="url"
                                    value={editLinks[key].url}
                                    onChange={(e) => handleInputChange(e, key)}
                                    valid={
                                        editLinks[key].valid &&
                                        editLinks[key].changed
                                    }
                                    invalid={
                                        !editLinks[key].valid &&
                                        editLinks[key].changed
                                    }
                                />
                                <FormFeedback>
                                    {editLinks[key].message}
                                </FormFeedback>
                            </Col>
                            <Col className="col col-lg-2">
                                <Button
                                    color="primary"
                                    onClick={() => handleSaveLink(key)}
                                    disabled={
                                        !editLinks[key].valid ||
                                        !editLinks[key].changed
                                    }>
                                    {t("editPocLinks.save")}
                                </Button>
                            </Col>
                        </Row>
                    </FormGroup>
                </Form>
            ))}
        </>
    );
}
