import { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import "styles/EditAuthor.scss";
import AuthorService from "../services/AuthorService";
import CenteredLoader from "../components/CenteredLoader";
import {
    Button,
    Form,
    FormGroup,
    Input,
    Label,
    Card,
    CardBody,
} from "reactstrap";
import Layout from "layouts/Layout";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import AuthorAvatarImage from "../components/AuthorAvatarImage";
import AuthorImageCrop from "../components/AuthorImageCrop";

const EditAuthor = () => {
    const { authorId } = useParams<{ authorId: string }>();
    const numericAuthorId = authorId ? parseInt(authorId, 10) : 0;
    const [author, setAuthor] = useState<any | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [shortBio, setShortBio] = useState<string>("");
    const [isImageCropModalOpen, setIsImageCropModalOpen] = useState(false);
    const { t } = useTranslation();

    const fetchAuthor = useCallback(async () => {
        if (numericAuthorId) {
            setIsLoading(true);
            try {
                const response = await AuthorService.getAuthor(numericAuthorId);
                setAuthor(response.data);
                setShortBio(response.data.shortBio || "");
                setIsLoading(false);
            } catch (error) {
                console.error("Failed to fetch author", error);
                setIsLoading(false);
            }
        } else {
            console.error("Author ID is undefined");
        }
    }, [numericAuthorId]);

    useEffect(() => {
        fetchAuthor();
    }, [fetchAuthor]);

    const handleBioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setShortBio(e.target.value);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (numericAuthorId) {
            try {
                await AuthorService.updateAuthorBio(numericAuthorId, shortBio);
                toast.success(t("editAuthor.bioUpdateSuccess"));
            } catch (error) {
                console.error("Failed to update bio", error);
                toast.error(t("editAuthor.bioUpdateError"));
            }
        } else {
            console.error("Author ID is undefined");
        }
    };

    const toggleImageCropModal = () => {
        setIsImageCropModalOpen(!isImageCropModalOpen);
    };

    return (
        <Layout>
            <Card className="edit-author-form">
                <CardBody>
                    {isLoading ? (
                        <CenteredLoader />
                    ) : (
                        <>
                            <h1>{t("editAuthor.editAuthorTitle")}</h1>
                            <Form onSubmit={handleSubmit}>
                                <FormGroup>
                                    {author && (
                                        <div
                                            onClick={toggleImageCropModal}
                                            style={{ cursor: "pointer" }}>
                                            <AuthorAvatarImage
                                                photourl={author.pictureUrl}
                                                pixelWidth={150}
                                            />
                                        </div>
                                    )}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="shortBio">
                                        {t("editAuthor.shortBio")}
                                    </Label>
                                    <Input
                                        type="textarea"
                                        name="shortBio"
                                        id="shortBio"
                                        value={shortBio}
                                        onChange={handleBioChange}
                                        rows="5"
                                    />
                                </FormGroup>
                                <div className="form-button-group">
                                    <Button
                                        type="submit"
                                        color="primary"
                                        className="submit-button">
                                        {t("editAuthor.updateButton")}
                                    </Button>
                                </div>
                            </Form>
                            <AuthorImageCrop
                                widthAspect={1}
                                heightAspect={1}
                                authorId={numericAuthorId}
                                profileImageUrl={author?.pictureUrl}
                                onSuccess={fetchAuthor}
                                isOpen={isImageCropModalOpen}
                                toggle={toggleImageCropModal}
                            />
                        </>
                    )}
                </CardBody>
            </Card>
        </Layout>
    );
};

export default EditAuthor;
