// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.language-picker--dropdown {
  text-align: right;
}
.language-picker--dropdown button {
  background-color: transparent;
  opacity: 50%;
}
.language-picker--dropdown button:hover {
  background-color: transparent;
  opacity: 100%;
}
.language-picker--dropdown .dropdown-menu {
  width: auto;
}
.language-picker--dropdown .dropdown-toggle::after {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/styles/LanguagePicker.scss"],"names":[],"mappings":"AACI;EACI,iBAAA;AAAR;AAEQ;EACI,6BAAA;EACA,YAAA;AAAZ;AAEY;EACI,6BAAA;EACA,aAAA;AAAhB;AAIQ;EACI,WAAA;AAFZ;AAKQ;EACI,aAAA;AAHZ","sourcesContent":[".language-picker {\n    &--dropdown {\n        text-align: right;\n\n        button {\n            background-color: transparent;\n            opacity: 50%;\n\n            &:hover {\n                background-color: transparent;\n                opacity: 100%;\n            }\n        }\n\n        .dropdown-menu {\n            width: auto;\n        }\n\n        .dropdown-toggle::after {\n            display: none;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
