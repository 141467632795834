import pocApiAxios from "./PocApiAxiosBase";
import AuthService from "../auth/AuthService";

const AuthorService = {
    getAuthor(authorId: number): Promise<any> {
        return pocApiAxios.get<any>(`/authors/${authorId}`);
    },

    getAllAuthors(): Promise<any> {
        return pocApiAxios.get<any>("/authors");
    },

    async getPocAuthors(pocId: number): Promise<any> {
        let authToken = await AuthService.getToken();
        return pocApiAxios.get<any>(`/pocs/${pocId}/authors`, {
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        });
    },

    async addPocAuthor(pocId: number, authorId: string): Promise<any> {
        let authToken = await AuthService.getToken();
        const options = {
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        };

        return pocApiAxios.post<any>(
            `/pocs/${pocId}/authors/${authorId}`,
            {},
            options,
        );
    },

    async removePocAuthor(pocId: number, authorId: string): Promise<any> {
        let authToken = await AuthService.getToken();
        return pocApiAxios.delete<any>(`/pocs/${pocId}/authors/${authorId}`, {
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        });
    },
    async updateAuthorBio(authorId: number, bio: string): Promise<any> {
        let authToken = await AuthService.getToken();
        const options = {
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        };

        return pocApiAxios.put<any>(
            `/authors/${authorId}/update-bio`,
            { shortBio: bio },
            options,
        );
    },
};

export default AuthorService;
