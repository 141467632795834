import {
    useCodeBlockEditorContext,
    CodeBlockEditorDescriptor,
} from "@mdxeditor/editor";
const defaultSnippetContent = `
export default function App() {
  return (
    <div className="App">
      <h1>Hello CodeSandbox</h1>
      <h2>Start editing to see some magic happen!</h2>
    </div>
  );
}
`.trim();

export const mySandpackConfig = {
    defaultPreset: "react",
    presets: [
        {
            label: "React",
            name: "react",
            meta: "live",
            sandpackTemplate: "react",
            sandpackTheme: "light",
            snippetFileName: "/App.js",
            snippetLanguage: "jsx",
            initialSnippetContent: defaultSnippetContent,
        },
        {
            label: "Virtuoso",
            name: "virtuoso",
            meta: "live virtuoso",
            sandpackTemplate: "react-ts",
            sandpackTheme: "light",
            snippetFileName: "/App.tsx",
            initialSnippetContent: defaultSnippetContent,
            dependencies: {
                "react-virtuoso": "latest",
                "@ngneat/falso": "latest",
            },
        },
    ],
};

const createCustomCodeEditorDescriptor = (
    language: string,
): CodeBlockEditorDescriptor => ({
    match: (lang, meta) => lang === language,
    priority: 0,
    Editor: (props) => {
        const cb = useCodeBlockEditorContext();
        return (
            <div onKeyDown={(e) => e.nativeEvent.stopImmediatePropagation()}>
                <textarea
                    rows={10}
                    cols={50}
                    defaultValue={props.code}
                    onChange={(e) => cb.setCode(e.target.value)}
                />
            </div>
        );
    },
});

export const JavaCodeEditorDescriptor =
    createCustomCodeEditorDescriptor("java");
export const PythonCodeEditorDescriptor =
    createCustomCodeEditorDescriptor("python");
export const CSharpCodeEditorDescriptor =
    createCustomCodeEditorDescriptor("csharp");
export const ShellBashCodeEditorDescriptor =
    createCustomCodeEditorDescriptor("bash");
export const PhpCodeEditorDescriptor = createCustomCodeEditorDescriptor("php");
export const XmlCodeEditorDescriptor = createCustomCodeEditorDescriptor("xml");
