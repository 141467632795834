// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `blockquote {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 40px;
  margin-inline-end: 40px;
  color: #e0e0e0;
  background-color: #333333;
  border-left: 4px solid #cccccc;
  padding: 0.5em 20px;
  font-style: italic;
}`, "",{"version":3,"sources":["webpack://./src/styles/Layout.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,uBAAA;EACA,qBAAA;EACA,yBAAA;EACA,uBAAA;EACA,cAAA;EACA,yBAAA;EACA,8BAAA;EACA,mBAAA;EACA,kBAAA;AACJ","sourcesContent":["blockquote {\n    display: block;\n    margin-block-start: 1em;\n    margin-block-end: 1em;\n    margin-inline-start: 40px;\n    margin-inline-end: 40px;\n    color: #e0e0e0;\n    background-color: #333333;\n    border-left: 4px solid #cccccc;\n    padding: 0.5em 20px;\n    font-style: italic;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
