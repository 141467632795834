import React, { useEffect, useState } from "react";
import {
    MDXEditor,
    UndoRedo,
    BoldItalicUnderlineToggles,
    toolbarPlugin,
    CodeToggle,
    listsPlugin,
    ListsToggle,
    linkPlugin,
    linkDialogPlugin,
    CreateLink,
    imagePlugin,
    InsertImage,
    tablePlugin,
    InsertTable,
    thematicBreakPlugin,
    InsertThematicBreak,
    InsertCodeBlock,
    ConditionalContents,
    codeMirrorPlugin,
    ChangeCodeMirrorLanguage,
    ShowSandpackInfo,
    codeBlockPlugin,
    sandpackPlugin,
    InsertSandpack,
    InsertAdmonition,
    directivesPlugin,
    AdmonitionDirectiveDescriptor,
    DiffSourceToggleWrapper,
    diffSourcePlugin,
    BlockTypeSelect,
    quotePlugin,
    headingsPlugin,
    frontmatterPlugin,
    markdownShortcutPlugin,
    Separator,
    MDXEditorMethods,
} from "@mdxeditor/editor";
import "@mdxeditor/editor/style.css";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import PocService from "../services/PocService";

import { CardBody, CardTitle, Button } from "reactstrap";
import { mySandpackConfig } from "../utils/MDXEditorUtil";
import { handleSubmitFormErrorResponse } from "../utils/FormUtil";
import {
    JavaCodeEditorDescriptor,
    PythonCodeEditorDescriptor,
    CSharpCodeEditorDescriptor,
    ShellBashCodeEditorDescriptor,
    PhpCodeEditorDescriptor,
    XmlCodeEditorDescriptor,
} from "../utils/MDXEditorUtil";
interface EditPocArticleProps {
    pocId: number;
    pocArticle: string | undefined;
    setPocArticle: (article: string) => void;
    setIsArticleDirty: (isDirty: boolean) => void;
}

const EditPocArticle: React.FC<EditPocArticleProps> = ({
    pocId,
    pocArticle,
    setPocArticle,
    setIsArticleDirty,
}) => {
    const ref = React.useRef<MDXEditorMethods>(null);
    const { t } = useTranslation();
    const [submitError, setSubmitError] = useState<string>("");

    useEffect(() => {
        ref.current?.setMarkdown(pocArticle || "");
    }, [pocArticle]);

    const handleSave = async () => {
        if (pocId) {
            if (ref.current) {
                try {
                    await PocService.updateArticleText(
                        pocId,
                        ref.current.getMarkdown(),
                    );
                    setSubmitError("");
                    setPocArticle(ref.current.getMarkdown());
                    setIsArticleDirty(false);
                    toast.success(t("editPocArticle.articleSavedSuccessfully"));
                } catch (error) {
                    handleSubmitFormErrorResponse(
                        error,
                        null,
                        setSubmitError,
                        () => {},
                        t,
                    );
                }
            } else {
                handleSubmitFormErrorResponse(
                    Error("Mdx is empty"),
                    null,
                    setSubmitError,
                    () => {},
                    t,
                );
            }
        }
    };

    const handleMarkdownChange = (newMarkdown: string) => {
        ref.current?.setMarkdown(newMarkdown);
        if (pocArticle !== ref.current?.getMarkdown()) {
            setIsArticleDirty(true);
        } else {
            setIsArticleDirty(false);
        }
    };

    return (
        <CardBody>
            <CardTitle tag="h5">
                {t("editPocArticle.create/editPocArticle")}
            </CardTitle>

            <MDXEditor
                ref={ref}
                className="dark-theme"
                markdown={
                    ref.current && ref.current.getMarkdown()
                        ? ref.current.getMarkdown()
                        : ""
                }
                onChange={(value) => handleMarkdownChange(value)}
                plugins={[
                    toolbarPlugin({
                        toolbarContents: () => (
                            <ConditionalContents
                                options={[
                                    {
                                        when: (editor) =>
                                            editor?.editorType === "codeblock",
                                        contents: () => (
                                            <>
                                                <ChangeCodeMirrorLanguage />
                                            </>
                                        ),
                                    },
                                    {
                                        when: (editor) =>
                                            editor?.editorType === "sandpack",
                                        contents: () => <ShowSandpackInfo />,
                                    },
                                    {
                                        fallback: () => (
                                            <>
                                                <DiffSourceToggleWrapper>
                                                    <UndoRedo />
                                                    <BoldItalicUnderlineToggles />
                                                    <CodeToggle />
                                                    <ListsToggle />
                                                    <Separator />
                                                    <BlockTypeSelect />
                                                    <CreateLink />
                                                    <InsertImage />
                                                    <InsertTable />
                                                    <InsertThematicBreak />
                                                    <InsertCodeBlock />
                                                    <InsertSandpack />
                                                    <InsertAdmonition />
                                                    <Separator />
                                                </DiffSourceToggleWrapper>
                                            </>
                                        ),
                                    },
                                ]}
                            />
                        ),
                    }),
                    quotePlugin(),
                    headingsPlugin({
                        allowedHeadingLevels: [1, 2, 3],
                    }),
                    listsPlugin(),
                    linkDialogPlugin(),
                    linkPlugin(),
                    imagePlugin(),
                    tablePlugin(),
                    thematicBreakPlugin(),
                    frontmatterPlugin(),
                    codeBlockPlugin({
                        defaultCodeBlockLanguage: "js",
                        codeBlockEditorDescriptors: [
                            JavaCodeEditorDescriptor,
                            PythonCodeEditorDescriptor,
                            CSharpCodeEditorDescriptor,
                            ShellBashCodeEditorDescriptor,
                            PhpCodeEditorDescriptor,
                            XmlCodeEditorDescriptor,
                        ],
                    }),
                    codeMirrorPlugin({
                        codeBlockLanguages: {
                            txt: "text",
                            js: "JavaScript",
                            tsx: "TypeScript",
                            html: "HTML",
                            css: "CSS",
                            java: "Java",
                            python: "Python",
                            csharp: "C#",
                            bash: "Bash",
                            php: "PHP",
                            xml: "XML",
                        },
                    }),
                    sandpackPlugin({
                        sandpackConfig: mySandpackConfig as any,
                    }),
                    directivesPlugin({
                        directiveDescriptors: [AdmonitionDirectiveDescriptor],
                    }),
                    diffSourcePlugin({
                        viewMode: "rich-text",
                        diffMarkdown: "boo",
                    }),
                    markdownShortcutPlugin(),
                ]}
            />
            <div className="form-button-group">
                <Button
                    className="btn btn-primary mt-3 me-4"
                    type="submit"
                    color="primary"
                    onClick={() => handleSave()}>
                    {t("editPocArticle.save")}
                </Button>
            </div>
            {submitError && <div className="error-message">{submitError}</div>}
        </CardBody>
    );
};

export default EditPocArticle;
