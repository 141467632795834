// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tags-container {
  display: flex;
  align-items: start;
  gap: 10px;
  flex-wrap: wrap;
  word-break: break-all;
}

.authors-container {
  display: flex;
  align-items: start;
  gap: 10px;
  flex-wrap: wrap;
}

.poc-cover-wrapper {
  position: relative;
  overflow: hidden;
}

.poc-draft-label {
  top: 100px;
  left: -50px;
  position: absolute;
  background-color: #9e0101;
  width: 210px;
  height: 40px;
  transform: rotate(-45deg);
  transform-origin: 0 0;
  box-shadow: 0px 0px 7px 1px #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1em;
}

.poc-description {
  white-space: pre-line;
}

.poc-title {
  word-break: break-all;
}`, "",{"version":3,"sources":["webpack://./src/styles/PublishedPoc.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,kBAAA;EACA,SAAA;EACA,eAAA;EACA,qBAAA;AACJ;;AACA;EACI,aAAA;EACA,kBAAA;EACA,SAAA;EACA,eAAA;AAEJ;;AAAA;EACI,kBAAA;EACA,gBAAA;AAGJ;;AAAA;EACI,UAAA;EACA,WAAA;EACA,kBAAA;EACA,yBAAA;EACA,YAAA;EACA,YAAA;EACA,yBAAA;EACA,qBAAA;EACA,mCAAA;EAEA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,cAAA;AAEJ;;AAAA;EACI,qBAAA;AAGJ;;AADA;EACI,qBAAA;AAIJ","sourcesContent":[".tags-container {\n    display: flex;\n    align-items: start;\n    gap: 10px;\n    flex-wrap: wrap;\n    word-break: break-all;\n}\n.authors-container {\n    display: flex;\n    align-items: start;\n    gap: 10px;\n    flex-wrap: wrap;\n}\n.poc-cover-wrapper {\n    position: relative;\n    overflow: hidden;\n}\n\n.poc-draft-label {\n    top: 100px;\n    left: -50px;\n    position: absolute;\n    background-color: #9e0101;\n    width: 210px;\n    height: 40px;\n    transform: rotate(-45deg);\n    transform-origin: 0 0;\n    box-shadow: 0px 0px 7px 1px #000000;\n\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    color: white;\n    font-size: 1em;\n}\n.poc-description {\n    white-space: pre-line;\n}\n.poc-title {\n    word-break: break-all;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
