// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.invisible-lines-table tr,
.invisible-lines-table td {
  border-color: transparent;
}

.file-name {
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mime-type {
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.action-buttons {
  display: flex;
  align-items: center;
  gap: 5px;
}`, "",{"version":3,"sources":["webpack://./src/styles/ManageArticleFiles.scss"],"names":[],"mappings":"AAAA;;EAEI,yBAAA;AACJ;;AACA;EACI,gBAAA;EACA,mBAAA;EACA,gBAAA;EACA,uBAAA;AAEJ;;AAAA;EACI,gBAAA;EACA,mBAAA;EACA,gBAAA;EACA,uBAAA;AAGJ;;AAAA;EACI,aAAA;EACA,mBAAA;EACA,QAAA;AAGJ","sourcesContent":[".invisible-lines-table tr,\n.invisible-lines-table td {\n    border-color: transparent;\n}\n.file-name {\n    max-width: 300px;\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n}\n.mime-type {\n    max-width: 100px;\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n}\n\n.action-buttons {\n    display: flex;\n    align-items: center;\n    gap: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
