import { useEffect, useState, useCallback } from "react";

import { Notification } from "../components/Notification";

import PocService from "../services/PocService";
import { useParams } from "react-router-dom";

import { Card, Button, CardFooter } from "reactstrap";
import "../styles/EditPoCArticle.scss";
import ManageArticleFiles from "./ManageArticleFiles";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import AddAndRemoveAuthorsModal from "../components/AddAndRemoveAuthorsModal";
import TagsModal from "../components/TagsModal";
import ManagePublishedDialogModal from "components/ManagePublishedDialogModal";
import ImageCrop from "components/ImageCrop";
import PocEditLinks from "components/PocEditLinks";
import CreatePoC from "./CreatePoC";
import Layout from "layouts/Layout";
import EditPocArticle from "./PocArticle";
import CenteredLoader from "components/CenteredLoader";
import LeavePageConfirmationDialog from "components/LeavePageConfimationDialog";
import { useFormPrompt } from "hooks/useFormPrompt";

interface EditPoCProps {
    pocIdProp?: number;
}

export default function EditPoC({ pocIdProp }: EditPoCProps) {
    const { pocId: pocIdParam } = useParams<{ pocId?: string }>();
    const pocIdAsInt = pocIdProp ?? parseInt(pocIdParam || "", 10);
    const [isSubmitting, setSubmitting] = useState<boolean>(false);
    const [isAuthorsModalOpen, setIsAuthorsModalOpen] = useState(false);
    const [isTagsModalOpen, setIsTagsModalOpen] = useState(false);
    const [coverImageUrl, setCoverImageUrl] = useState<string | null>(null);
    const [isManagePublishedDialogOpen, setIsManagePublishedDialogOpen] =
        useState(false);
    const [isPublished, setIsPublished] = useState(false);
    const [pocArticle, setPocArticle] = useState<string>();
    const [pocState, setPocState] = useState<{
        caseStudyLink: string;
        sourceCodeLink: string;
    }>({ caseStudyLink: "", sourceCodeLink: "" });
    const [imageVersion, setImageVersion] = useState(0);
    const { t } = useTranslation();
    const [isCreateFormDirty, setIsCreateFormDirty] = useState<boolean>(false);
    const [isArticleDirty, setIsArticleDirty] = useState<boolean>(false);
    const [isCaseStudyLinkDirty, setCaseStudyLinkDirty] =
        useState<boolean>(false);
    const [istSourceCodeLinkDirty, setSourceCodeLinkDirty] =
        useState<boolean>(false);
    const toggleAuthorsModal = () => {
        setIsAuthorsModalOpen(!isAuthorsModalOpen);
    };

    const toggleTagsModal = () => {
        setIsTagsModalOpen(!isTagsModalOpen);
    };

    const toggleManagePublishedDialog = () => {
        setIsManagePublishedDialogOpen(!isManagePublishedDialogOpen);
    };

    const fetchPoC = useCallback(async () => {
        if (pocIdAsInt) {
            setSubmitting(true);
            try {
                const response = await PocService.getPoc(pocIdAsInt);
                setPocArticle(response.data.articleText);
                setPocState({
                    caseStudyLink: response.data.caseStudyLink
                        ? decodeURIComponent(response.data.caseStudyLink)
                        : "",
                    sourceCodeLink: response.data.sourceCodeLink
                        ? decodeURIComponent(response.data.sourceCodeLink)
                        : "",
                });
                setIsPublished(response.data.published);
                setCoverImageUrl(response.data.coverImageUrl || null);
                setSubmitting(false);
            } catch (error) {
                console.error("Error fetching PoC:", error);
                setSubmitting(false);
            }
        }
        setImageVersion((prev) => prev + 1);
    }, [pocIdAsInt]);

    useEffect(() => {
        fetchPoC();
    }, [fetchPoC]);

    const handleLinkUpdate = (
        type: "sourceCode" | "caseStudy",
        url: string,
    ) => {
        setPocState((prevState) => ({
            ...prevState,
            [type]: url,
        }));
    };

    useFormPrompt(
        isCaseStudyLinkDirty ||
            istSourceCodeLinkDirty ||
            isArticleDirty ||
            isCreateFormDirty,
    );

    return (
        <Layout>
            {isSubmitting ? (
                <CenteredLoader />
            ) : (
                <div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ flex: 1 }}>
                            <CreatePoC
                                pocId={pocIdProp ?? pocIdAsInt}
                                onPocCreated={() => {}}
                                setIsCreateFormDirty={setIsCreateFormDirty}
                            />
                        </div>
                        <div style={{ flex: 1 }}>
                            <ImageCrop
                                key={imageVersion}
                                widthAspect={600}
                                heightAspect={337}
                                pocId={pocIdAsInt}
                                coverImageUrl={coverImageUrl}
                                refreshCoverImage={fetchPoC}
                                onSuccess={() => {
                                    toast.success(
                                        t(
                                            "uploadArticleFiles.fileUploadedSuccessfully",
                                        ),
                                    );
                                }}
                            />
                        </div>
                    </div>
                    <Notification />
                    {pocIdAsInt !== null && (
                        <ManageArticleFiles pocId={pocIdAsInt} />
                    )}
                    <Button
                        className="btn btn-primary mt-3 me-4 mb-3"
                        type="submit"
                        color="primary"
                        onClick={toggleAuthorsModal}>
                        {t("editPocArticle.buttons.manageAuthors")}
                    </Button>
                    <Button
                        className="btn btn-primary mt-3 me-4 mb-3"
                        type="submit"
                        color="primary"
                        onClick={toggleTagsModal}>
                        {t("editPocArticle.buttons.manageTags")}
                    </Button>
                    <Button
                        className="btn btn-primary mt-3 me-4 mb-3"
                        type="submit"
                        color="primary"
                        onClick={toggleManagePublishedDialog}>
                        {isPublished
                            ? t("editPocArticle.buttons.unpublishPoC")
                            : t("editPocArticle.buttons.publishPoC")}
                    </Button>
                    <ManagePublishedDialogModal
                        pocId={pocIdAsInt}
                        isPublished={isPublished}
                        setIsPublished={setIsPublished}
                        isModalOpen={isManagePublishedDialogOpen}
                        setIsModalOpen={setIsManagePublishedDialogOpen}
                        toggleModal={toggleManagePublishedDialog}
                    />
                    <AddAndRemoveAuthorsModal
                        pocId={pocIdAsInt}
                        isModalOpen={isAuthorsModalOpen}
                        setIsModalOpen={setIsAuthorsModalOpen}
                        toggleModal={toggleAuthorsModal}
                    />
                    <TagsModal
                        pocId={pocIdAsInt}
                        isModalOpen={isTagsModalOpen}
                        setIsModalOpen={setIsTagsModalOpen}
                        toggleModal={toggleTagsModal}
                    />
                    <Card
                        className="poc-card edit-poc-form"
                        data-test="EditPoCForm">
                        <EditPocArticle
                            pocId={pocIdAsInt}
                            pocArticle={pocArticle}
                            setPocArticle={setPocArticle}
                            setIsArticleDirty={setIsArticleDirty}
                        />
                        <CardFooter>
                            <PocEditLinks
                                id={pocIdAsInt}
                                sourceCodeLink={pocState.sourceCodeLink}
                                caseStudyLink={pocState.caseStudyLink}
                                onUpdate={handleLinkUpdate}
                                setSourceCodeLinkDirty={setSourceCodeLinkDirty}
                                setCaseStudyLinkDirty={setCaseStudyLinkDirty}
                            />
                        </CardFooter>
                    </Card>
                    <LeavePageConfirmationDialog
                        hasChanges={
                            isCaseStudyLinkDirty ||
                            istSourceCodeLinkDirty ||
                            isArticleDirty ||
                            isCreateFormDirty
                        }
                    />
                </div>
            )}
        </Layout>
    );
}
