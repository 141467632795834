// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.name-filter-input {
  width: 100%;
}

.filters-container {
  margin-bottom: 1em;
}

.filters-author-dropdown,
.filters-tag-dropdown {
  width: 100%;
}

.dropdown-menu {
  width: 100%;
}

.dropdown-toggle {
  width: 100%;
  text-align: left;
}

.col-lg-1,
.col-lg-3 {
  padding-right: 5px;
  padding-left: 5px;
}

.published-filter {
  margin-right: 220px;
}`, "",{"version":3,"sources":["webpack://./src/styles/PocsFilterGrid.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAEA;;EAEI,WAAA;AACJ;;AAEA;EACI,WAAA;AACJ;;AAEA;EACI,WAAA;EACA,gBAAA;AACJ;;AAEA;;EAEI,kBAAA;EACA,iBAAA;AACJ;;AACA;EACI,mBAAA;AAEJ","sourcesContent":[".name-filter-input {\n    width: 100%;\n}\n\n.filters-container {\n    margin-bottom: 1em;\n}\n\n.filters-author-dropdown,\n.filters-tag-dropdown {\n    width: 100%;\n}\n\n.dropdown-menu {\n    width: 100%;\n}\n\n.dropdown-toggle {\n    width: 100%;\n    text-align: left;\n}\n\n.col-lg-1,\n.col-lg-3 {\n    padding-right: 5px;\n    padding-left: 5px;\n}\n.published-filter {\n    margin-right: 220px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
